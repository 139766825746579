
import { defineComponent, ref, computed, onMounted } from 'vue'
import { Toaster } from '@/common/Toaster'
import { Project } from '@/model/Project'
import { USER_ROLES } from '@/model/User'
import router from '@/router'
import { __ } from '@/locales'
import { storeAuth, StoreGettersAuth } from '@/store/auth'
import { StoreActionsProject, storeProject } from '@/store/project'

export interface CustomFile {
  base64: string
  extention: string
}

const defaultProject: Project = {
  id: 0,
  name: '',
  url: '',
}

export default defineComponent({
  name: `CardProject`,
  components: {},
  props: {
    project: {
      type: Object,
      required: false,
      default: defaultProject,
    },
  },
  emits: [],
  setup(
    props: Readonly<{
      project: Project
    }>,
  ) {
    const src = ref('')
    const modifier = ref('Modifier')

    const users = computed((): string => {
      const count = props.project?.userIds?.length || 0
      return `utilisateur${count > 1 ? 's' : ''}`
    })

    const imageTexte = computed(() => {
      if (src.value) {
        return __('editImage')
      } else {
        return __('addImage')
      }
    })

    const canEditImage = computed((): boolean => {
      return (
        storeAuth.getters[StoreGettersAuth.ROLE] === USER_ROLES.SUPERADMIN ||
        storeAuth.getters[StoreGettersAuth.ROLE] === USER_ROLES.ADMIN
      )
    })

    onMounted(() => {
      storeProject
        .dispatch(StoreActionsProject.GET_IMAGE, props.project.id)
        .then((json) => {
          src.value = json.data
        })
    })

    const goToProject = () => {
      router.push({
        path: `/projects/${props.project.id}`,
        params: { id: '' + props.project.id },
      })
    }

    const goToApp = () => {
      window.open(props.project.url, '_blank')
    }

    const saveProjectImage = async (event: CustomEvent) => {
      const file = event.detail.file
      const res = await storeProject.dispatch(StoreActionsProject.SET_IMAGE, {
        base64: event.detail.base64,
        file: { name: file.name },
        projectId: props.project.id,
      })
      if (res.success) src.value = res.data
      Toaster.toast(res)
    }

    return {
      src,
      modifier,
      users,
      imageTexte,
      canEditImage,
      goToProject,
      goToApp,
      saveProjectImage,
    }
  },
})
