
import { defineComponent, onMounted, computed, ref, Ref } from 'vue'
import CardProject from '@/components/core/project/CardProject.vue'
import AddProject from '@/components/core/project/AddProject.vue'
import { Project } from '@/model/Project'
import { USER_ROLES } from '@/model/User'
import { ResetableComponent } from '@/model/ResetableComponent'
import { Toaster } from '@/common/Toaster'
import {
  storeProject,
  StoreActionsProject,
  StoreGettersProject,
} from '@/store/project'
import { StoreGettersAuth, storeAuth } from '@/store/auth'

export default defineComponent({
  name: `ViewProjects`,
  components: { CardProject, AddProject },
  props: {},
  emits: [],
  setup() {
    const addProjectEl: Ref<ResetableComponent> = ref(null)
    const loading = ref(false)

    onMounted(async () => {
      if (!projects.value.length) {
        loading.value = true
      }
      await storeProject.dispatch(StoreActionsProject.GET_ALL)
      loading.value = false
    })

    const projects = computed(
      () => storeProject.getters[StoreGettersProject.PROJECTS] as Project[],
    )
    const isSuperadmin = computed(
      () => storeAuth.getters[StoreGettersAuth.ROLE] === USER_ROLES.SUPERADMIN,
    )

    const addProject = async (project: Project) => {
      const res = await storeProject.dispatch(StoreActionsProject.ADD, project)
      Toaster.toast(res)
      if (res.success) {
        addProjectEl.value.reset()
      }
    }

    return {
      projects,
      isSuperadmin,
      addProject,
      addProjectEl,
      loading,
    }
  },
})
