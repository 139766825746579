import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/default-user.png'


_pushScopeId("data-v-6379b44d")
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "container"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "img",
  src: _imports_0
}
const _hoisted_5 = { class: "title" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createBlock(_component_base_card, { class: "card-project" }, {
    default: _withCtx(() => [
      (!_ctx.project)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t('noData')), 1))
        : _createCommentVNode("", true),
      (_ctx.project)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", {
              class: "logo",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToProject()))
            }, [
              (_ctx.src)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    class: "img",
                    src: _ctx.src
                  }, null, 8, _hoisted_3))
                : _createCommentVNode("", true),
              (!_ctx.src)
                ? (_openBlock(), _createElementBlock("img", _hoisted_4))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("h1", {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToProject()))
              }, _toDisplayString(_ctx.project.name), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}