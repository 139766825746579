import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-d9ae50d4")
const _hoisted_1 = ["title"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_project_form_card = _resolveComponent("project-form-card")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["add-project", { adding: _ctx.adding }])
  }, [
    (!_ctx.adding)
      ? (_openBlock(), _createElementBlock("dig-ui-icon", {
          key: 0,
          type: "add",
          class: _normalizeClass(["add-button", { background: _ctx.background }]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.adding = true)),
          title: _ctx.$t('add')
        }, null, 10, _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.adding)
      ? (_openBlock(), _createBlock(_component_base_card, {
          key: 1,
          class: "form"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_project_form_card, {
              title: _ctx.$t('Project.new'),
              onValidate: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addProject($event))),
              onCancel: _cache[2] || (_cache[2] = ($event: any) => (_ctx.adding = false)),
              project: _ctx.project,
              disabled: _ctx.disabled
            }, null, 8, ["title", "project", "disabled"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 2))
}