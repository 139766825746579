
import { defineComponent, SetupContext, ref } from 'vue'
import { Project, DEFAULT_PROJECT } from '@/model/Project'
import ProjectFormCard from './ProjectFormCard.vue'

export default defineComponent({
  name: `AddProject`,
  components: { ProjectFormCard },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    background: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['add'],
  setup(props: Readonly<any>, context: SetupContext) {
    const project = ref(DEFAULT_PROJECT)
    const adding = ref(false)

    const addProject = (project: Project) => {
      context.emit('add', project)
    }

    const reset = () => {
      adding.value = false
      project.value = {}
    }

    return {
      project,
      adding,
      addProject,
      reset,
    }
  },
})
